<template>
<div class="checkAssetResultView">
  <div>
    <div class="title">资产名称</div>
    <div class="content">{{ $valueIsExist(dataInfo, 'name') == false ? '-' : dataInfo['name'] }}</div>
    <div style="clear: both"></div>
  </div>
  <div>
    <div class="title">所属科室</div>
    <div class="content">{{ $valueIsExist(dataInfo, 'tenantName') == false ? '-' : dataInfo['tenantName']  }}</div>
    <div style="clear: both"></div>
  </div>
  <div>
    <div class="title">所属分类</div>
    <div class="content">{{ $valueIsExist(dataInfo, 'categoryName') == false ? '-' : dataInfo['categoryName']  }}</div>
    <div style="clear: both"></div>
  </div>
  <div>
    <div class="title">是否盘到</div>
    <div  :style="{color:inventoried ? 'green' : 'red'}" class="content">{{ inventoried ? '已盘到' : '未盘到'  }}</div>
    <div style="clear: both"></div>
  </div>
  <div>
    <div>
      <div class="subItem">
        <div class="subTitle">使用率</div>
        <div class="subContent">{{ $valueIsExist(dataInfo, 'usageRate') == false ? '-' : (dataInfo['usageRate'] + '%')  }}</div>
      </div>
      <div class="subItem">
        <div class="subTitle">闲置率</div>
        <div class="subContent">{{ $valueIsExist(dataInfo, 'idleRate') == false ? '-' : (dataInfo['idleRate'] + '%') }}</div>
      </div>
      <div class="subItem">
        <div class="subTitle">最近状态</div>
        <div class="subContent">{{ $valueIsExist(dataInfo, 'status') == false ? '-' :deviceStatus(dataInfo['status']) }}</div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'checkAssetResultView',
  props: {
    dataInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed:{
    inventoried() {
      if (this.$valueIsExist(this.dataInfo, 'inventoried') == false) {
        return false
      }
      return this.dataInfo['inventoried']
    },
  },
  methods:{
    deviceStatus: function(status) {
      if (status == 1) {
        return '开机'
      } else if (status == 2) {
        return '脱落'
      } else if (status == 3) {
        return '报障'
      } else if (status == 4) {
        return '信号丢失'
      } else if (status == 0) {
        return '关机'
      } else if (status == -1) {
        return '未启用'
      } else if (status == -2) {
        return '维修'
      }
      return '-'
    },
  }
}
</script>

<style lang="scss" scoped>

.checkAssetResultView {

  .title {
    width: 60px;
    text-align: right;
    height: 30px;
    line-height: 25px;
    font-size: 13px;
    float: left;
  }

  .content {
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100% - 80px);
    text-align: right;
    line-height: 25px;
    font-size: 13px;
    text-align: left;
    float: right;
    word-break: break-all;
  }

  .subItem {
    width: 33.333%;
    float: left;

    .subTitle {
      width: 60px;
      text-align: right;
      height: 30px;
      line-height: 25px;
      font-size: 13px;
      float: left;
    }

    .subContent {
      margin-left: 5px;
      margin-right: 5px;
      width: calc(100% - 70px);
      text-align: right;
      line-height: 25px;
      font-size: 13px;
      text-align: left;
      float: right;
      word-break: break-all;
    }

  }

}

</style>
