<template>
  <div class="assetSimpleTable">
    <v-stack class="assetSimpleMsg" justify-content="flex-start">
      <h-stack class="assetSimpleMsgCell" justify-content="flex-start">
        <div class="assetSimpleMsgTitle">
          盘点日期
        </div>
        <div class="assetSimpleMsgContent">
          {{ formatDate(assetData.createDate) }}
        </div>
      </h-stack>

      <h-stack class="assetSimpleMsgCell" justify-content="flex-start">
        <div class="assetSimpleMsgTitle">
          科室
        </div>
        <div class="assetSimpleMsgContent">
          {{ tenantName }}
        </div>
      </h-stack>

      <h-stack class="assetSimpleMsgCell" justify-content="flex-start">
        <div class="assetSimpleMsgTitle">
          资产分类
        </div>
        <div class="assetSimpleMsgContent">
          {{ assetCategoryName }}
        </div>
      </h-stack>

      <h-stack class="assetSimpleMsgCell" justify-content="space-between">
        <h-stack justify-content="flex-start">
          <div class="assetSimpleMsgTitle">
            预盘量
          </div>
          <div class="assetSimpleMsgContent">
            {{ getNum(assetData.total) }}
          </div>
        </h-stack>
        <h-stack justify-content="flex-start">
          <div class="assetSimpleMsgTitle">
            实盘量
          </div>
          <div class="assetSimpleMsgContent">
            {{ getNum(assetData.realityNum) }}
          </div>
        </h-stack>
        <h-stack justify-content="flex-start">
          <div class="assetSimpleMsgTitle">
            盘亏(盈)
          </div>
          <div class="assetSimpleMsgContent">
            {{ (getNum(assetData.realityNum) - getNum(assetData.total)) }}
          </div>
        </h-stack>
      </h-stack>


    </v-stack>

    <div class="assetSimpleTableBGView">
      <check-asset-result
          :loading="loading"
          :height="'calc(100% - 40px)'"
          @checkAssetDetail="checkAssetDetail"
          :table-data="tableData"
      ></check-asset-result>
      <h-stack style="height: 40px">
        <el-pagination
            @current-change="currentChange"
            :page-size="pageSize"
            :pager-count="5"
            :current-page="page"
            layout="prev, pager, next"
            :total="totalCount"
        >
        </el-pagination>
      </h-stack>
    </div>

    <el-drawer
        :modal="false"
        size="calc(100% - 50px)"
        :visible.sync="showAssetDetail"
        :with-header="false"
        direction="btt"
    >
      <check-asset-detail :asset-data="assetData"></check-asset-detail>
    </el-drawer>

  </div>
</template>

<script>
import CheckAssetResult from '@/views/checkAsset/checkAssetResult/checkAssetResult'
import CheckAssetDetail from '@/views/checkAsset/checkAssetDetail/checkAssetDetail'
import HStack from '@/components/hStack/hStack'
import VStack from '@/components/vStack/vStack'

export default {
  name: 'assetSimpleTable',
  components: { VStack, HStack, CheckAssetDetail, CheckAssetResult },
  props: {
    detailData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    assetCategoryName() {
      if (typeof this.assetData['categoryList'] == 'undefined' || this.assetData['categoryList'] == null || this.assetCategoryList.length == 0) {
        return '全部科室'
      }

      let findAllList = (list, updateList) => {
        if (list.length == 0) {
          return
        }
        let newList = []
        for (let i = 0; i < list.length; i++) {
          let info = list[i]
          updateList.push({
            id: info['id'],
            name: info['name']
          })
          if (typeof info['children'] == 'undefined' || info['children'] == null) {
            continue
          }
          newList = newList.concat(info['children'])
        }
        findAllList(newList, updateList)
      }

      let categoryList = this.assetData['categoryList']
      let dataList = []
      findAllList(categoryList, dataList)
      let cList = []
      findAllList(this.assetCategoryList, cList)
      if (dataList.length == cList.length) {
        return '全部科室'
      }
      let name = ''
      for (let i = 0; i < dataList.length; i++) {
        let info = dataList[i];
        name = name == '' ? info['name'] : name + ',' + info['name']
      }
      return name
    },
    tenantName() {
      if (typeof this.assetData['tenantList'] == 'undefined' || this.assetData['tenantList'] == null) {
        return '全部科室'
      }
      let tenantList = this.assetData['tenantList']
      let name = ''
      for (let i = 0; i < tenantList.length; i++) {
        let info = tenantList[i]
        name = name == '' ? info['title'] : name + ',' + info['title']
      }
      return name == '' ? '全部科室' : name
    }
  },
  watch: {
    detailData: {
      handler(value) {
        for (const valueKey in value) {
          this.assetData[valueKey] = value[valueKey]
        }
      },
      immediate: true,
      deep: true
    }
  },
  data() {
    return {
      page: 1,
      totalCount: 0,
      pageSize: 50,
      loading: true,
      showAssetDetail: false,
      tableData: [], // 盘点数据
      assetData: {},
      assetCategoryList: []
    }
  },
  mounted() {
    this.$nextTick(() => {

    })
  },
  methods: {
    updateAssetCategoryList: function(assetCategoryList) {
      this.assetCategoryList = assetCategoryList
    },
    currentChange: function(index) {
      this.page = index
      let id = this.assetData.id
      if (typeof id == 'undefined' || id == null) {
        return
      }
      this.loading = true
      this.getDeviceInventoryDetailList({ id: id }).then(res => {
        this.totalCount = res.totalCount
        this.tableData = res.list
        this.activeName = 'checkResult'
        this.loading = false
      })
    },
    beforeRouteLeave: function() {
      if (this.showAssetDetail) {
        this.showAssetDetail = false
        return {
          result: false
        }
      }
      return {
        result: true
      }
    },
    getNum: function(value) {
      if (isNaN(value) || isNaN(parseInt(value))) {
        return 0
      }
      return parseInt(value)
    },
    checkAssetDetail: function(res) {
      let row = res.row
      for (const rowKey in row) {
        this.assetData[rowKey] = row[rowKey]
      }
      this.showAssetDetail = true
    },
    assetCheck: function() {
      this.loading = true
      let id = this.assetData.id
      if (typeof id == 'undefined' || id == null) {
        return
      }

      if (id != '') {
        this.page = 1
        this.totalCount = 0
        this.tableData = []

        Promise.all([this.getDeviceInventoryDetailList({ id: id }), this.getDeviceInventoryHistoryList({ id: id })]).then(res => {
          this.totalCount = res[0].totalCount
          this.tableData = res[0].list
          this.activeName = 'checkResult'
          this.loading = false
        })
        return
      }

      this.getDeviceInventoryInventory().then(res => {
        if (res.result == false) {
          return new Promise((resolve) => {
            resolve([{ list: [], totalCount: 0 }])
          })
        } else {
          this.assetData.id = res.id
          return Promise.all([this.getDeviceInventoryDetailList(res), this.getDeviceInventoryHistoryList(res)])
        }
      }).then(res => {
        let dataRes = res[0]
        this.totalCount = dataRes.totalCount
        this.tableData = dataRes.list
        this.activeName = 'checkResult'
        this.loading = false
      })
    },
    getDeviceInventoryHistoryList: function(result) {
      return new Promise(resolve => {
        let config = {
          id: result.id
        }
        this.$api.assetModule.getDeviceInventoryHistoryList(config).then(res => {
          let data = res.data
          let copyData = JSON.parse(JSON.stringify(this.assetData))
          for (const key in data) {
            let value = data[key]
            if (typeof value == 'undefined' || value == null) {
              value = ''
            }
            copyData[key] = value
          }
          this.assetData = copyData
          resolve({})
        }).catch(e => {
          resolve({})
        })
      })
    },

    getDeviceInventoryDetailList: function(result) {
      return new Promise(resolve => {
        let config = {
          id: result.id,
          page: this.page,
          pageSize: this.pageSize,
          tenantId: '',
          categoryId: '',
          status: ''
        }
        this.$api.assetModule.getDeviceInventoryDetailList(config).then(res => {
          resolve({ list: res.data, totalCount: res.totalCount })
        }).catch(e => {
          this.$message.error('盘点失败')
          resolve({ list: [], totalCount: 0 })
        })
      })
    },
    // 获取一键盘点Id
    getDeviceInventoryInventory: function() {
      let categoryId = this.assetData.categoryId
      if (typeof categoryId == 'undefined' || categoryId == null) {
        categoryId = ''
      }

      let tenantId = this.assetData.tenantId
      if (typeof tenantId == 'undefined' || tenantId == null) {
        tenantId = ''
      }

      return new Promise(resolve => {
        let config = {
          isTenantIdChild: 1,
          isCategoryIdChild: 1,
          brandId: '',
          modelId: '',
          categoryId: categoryId,
          tenantId: tenantId
        }
        this.$api.assetModule.getDeviceInventoryInventory(config).then(res => {
          resolve({ id: res.data, result: true })
        }).catch(e => {
          resolve({ result: false })
          this.$message.error('盘点失败')
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@styles/variables.scss";

.assetSimpleTable {
  z-index: 3000;
  width: 100%;
  //height: 100%;
  height: calc(100% - 50px);
  left: 0px;
  top: 50px;
  position: fixed;
  background-color: lightgray;

  .assetSimpleMsg {
    width: 100%;
    height: 135px;
    margin-bottom: 5px;
    background-color: white;

    .assetSimpleMsgCell {
      width: calc(100% - 20px);
      transform: translate(10px, 0px);
      margin-top: 10px;
    }

    .assetSimpleMsgTitle {
      width: 60px;
      color: $color_primary;
      height: 20px;
      line-height: 20px;
      text-align: left;
      font-size: 13px;
    }

    .assetSimpleMsgContent {
      margin-left: 10px;
      margin-right: 10px;
      min-width: 50px;
      max-width: calc(100% - 90px);
      color: gray;
      line-height: 20px;
      text-align: left;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-size: 13px;
    }
  }

  .assetSimpleTableBGView {
    width: 100%;
    height: calc(100% - 135px);
    background-color: white;
  }

}

</style>
