<!--<template>-->
<!--  <el-table-->
<!--      border-->
<!--      stripe-->
<!--      v-loading="loading"-->
<!--      :height="height"-->
<!--      :data="tableData"-->
<!--      class="checkAssetList" @row-click="rowClick">-->
<!--    <el-table-column-->
<!--        header-align="center"-->
<!--        fixed-->
<!--        prop="name"-->
<!--        label="资产名称"-->
<!--        width="calc(100%/7) <= 120px ? 120px : calc(100%/7)"-->
<!--    >-->
<!--    </el-table-column>-->
<!--    <el-table-column-->
<!--        header-align="center"-->
<!--        prop="tenantName"-->
<!--        label="所属科室"-->
<!--        width="calc(100%/7) <= 120px ? 120px : calc(100%/7)"-->
<!--    >-->
<!--    </el-table-column>-->
<!--    <el-table-column-->
<!--        header-align="center"-->
<!--        prop="categoryName"-->
<!--        label="所属分类"-->
<!--        width="150px"-->
<!--    >-->
<!--    </el-table-column>-->

<!--    <el-table-column-->
<!--        header-align="center"-->
<!--        prop="idleRate"-->
<!--        label="使用率"-->
<!--        width="calc(100%/7) <= 120px ? 120px : calc(100%/7)">-->
<!--      <template slot-scope="scope">-->
<!--        <span>{{ scope.row.usageRate + '%' }}</span>-->
<!--      </template>-->
<!--    </el-table-column>-->

<!--    <el-table-column-->
<!--        header-align="center"-->
<!--        prop="idleRate"-->
<!--        label="闲置率"-->
<!--        width="calc(100%/7) <= 120px ? 120px : calc(100%/7)">-->
<!--      <template slot-scope="scope">-->
<!--        <span>{{ scope.row.idleRate + '%' }}</span>-->
<!--      </template>-->
<!--    </el-table-column>-->

<!--    <el-table-column-->
<!--        header-align="center"-->
<!--        prop="status"-->
<!--        label="最近状态"-->
<!--        width="calc(100%/7) <= 120px ? 120px : calc(100%/7)">-->
<!--      <template slot-scope="scope">-->
<!--        <span>{{ deviceStatus(scope.row.status) }}</span>-->
<!--      </template>-->
<!--    </el-table-column>-->
<!--    <el-table-column-->
<!--        header-align="center"-->
<!--        prop="inventoried"-->
<!--        label="是否盘到"-->
<!--        width="calc(100%/7) <= 120px ? 120px : calc(100%/7)"-->
<!--    >-->
<!--      <template slot-scope="scope">-->
<!--          <span :style="{color:scope.row.inventoried ? 'green' : 'red'}">{{-->
<!--              scope.row.inventoried ? '已盘到' : '未盘到'-->
<!--            }}</span>-->
<!--      </template>-->
<!--    </el-table-column>-->
<!--  </el-table>-->
<!--</template>-->

<template>
  <el-table
      :show-header="false"
      border
      stripe
      v-loading="loading"
      :height="height"
      :data="tableData"
      class="checkAssetList" @row-click="rowClick">
    <el-table-column
        header-align="center"
        prop="inventoried"
        label="">
      <template slot-scope="scope">
        <check-asset-result-view :data-info="scope.row"></check-asset-result-view>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import CheckAssetResultView from '@/views/checkAsset/checkAssetResult/checkAssetResultView'
export default {
  name: 'checkAssetResult',
  components: { CheckAssetResultView },
  props:{
    loading:{
      type: Boolean,
      default: true,
    },
    height:{
      type:String,
      default:"calc(100% - 250px)"
    },
    tableData:{
      type:Array,
      default:() => {
        return []
      }
    }
  },
  data() {
    return {}
  },
  methods:{
    rowClick: function(row, event, column) {
      this.$emit('checkAssetDetail', {row, event, column})
    },
    deviceStatus: function(status) {
      if (status == 1) {
        return '开机'
      } else if (status == 2) {
        return '脱落'
      } else if (status == 3) {
        return '报障'
      } else if (status == 4) {
        return '信号丢失'
      } else if (status == 0) {
        return '关机'
      } else if (status == -1) {
        return '未启用'
      } else if (status == -2) {
        return '维修'
      }
      return '-'
    },
  },
}
</script>

<style lang="scss" scoped>

.checkAssetResultBGView {

}

</style>
